import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FoerdervereinePageTemplate } from '../templates/foerdervereine-template'

const FoerdervereinePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <FoerdervereinePageTemplate
      image = { frontmatter.image }
      list = { frontmatter.list }
    />
  )
}

FoerdervereinePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default FoerdervereinePage

export const foerdervereinePageQuery = graphql`
query FoerdervereineTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "foerdervereine-page"}}) {
    frontmatter {
      
      image
        {
        childImageSharp {
          gatsbyImageData(
            width: 1920,
          )}
        }
      
      list {
        text        
        link        
      }
    }
  }
}
`