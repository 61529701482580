import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import FoerdervereineListe from '../components/FoerdervereineListe'
import Layout from '../components/Layout';
import Spacer from '../components/Spacer';

export const FoerdervereinePageTemplate = ({
  image,
  list,
}) => (
    <div>
        <Helmet title="Fördervereine" defer={false}/>
        <Layout activePageIndex={6}>
          <Spacer height={50}/>
          <FoerdervereineListe data={ {image , list }}/>
        </Layout>
    </div>
);


FoerdervereinePageTemplate.propTypes = {
  image: PropTypes.object,
  list: PropTypes.array,
}