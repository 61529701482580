import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RedButtonFixedSize from './RedButtonFixedSize';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const Container = styled.div`
margin:30px 0;

& h2 {
    text-align:center;
    font-family:Montserrat;
    font-size:20;
    font-weight:700;        
    margin:25px 0 15px 0;
}

@media(min-width:993px){
    & h2 {
        font-size:30;
        margin:50px 0 30px 0;
    }
}
`

const Image = styled.div `
position:relative;
width:85%;
max-width:450px;
box-shadow:-1px 8px 8px rgba(0, 0, 0, 0.25);
height:auto;
margin:0 auto;

& >* {
    height:100%;
    width:100%;
}
`

const ListContainer = styled.div`
width:90%;
max-width:275px;
margin:75px auto;

@media(min-width:600px){
    max-width:none;
    width:560px;
}

@media(min-width:993px){
    width:845px;
}

@media(min-width:1200px){
    width:1130px;
}
`

const List = styled.div`
display:flex;
flex-flow:row wrap;
gap:10px;
`

const FoerdervereineListe = ({data}) => (
    
    <Container>
        <Image>
            <PreviewCompatibleImage imageInfo={data}/>
        </Image>
        <h2>{data.title}</h2>
        <ListContainer>
            <List>
            {
            data.list.map((item, i) => (
                <RedButtonFixedSize id={i} href={item.link}>{item.text}</RedButtonFixedSize>
            ))
            }
            </List>
        </ListContainer>
    </Container>
);

FoerdervereineListe.propTypes = {
    data: PropTypes.shape({
        image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        title: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.string,
        })),
    }),
};

export default FoerdervereineListe;